<script setup lang="ts">

</script>

<template>
  <div class="pt-2">
    <div class="text-gray-500 text-sm mb-2 p-1 px-2">
      <slot name="title" />
    </div>

    <div class="flex flex-col">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
