<script setup lang="ts">
import {useFetch} from "#app";

const props = defineProps({
  fileId: {
    type: String,
    required: false,
  },
})

const dialogRef = inject('dialogRef')

const fileId = props.fileId ?? dialogRef.value?.data?.fileId ?? null

const pdfPath = computed(() => {
  return `/api/v1/media/${fileId}/download`
})

const url = ref('')

const { execute, pending } = await useFetch(pdfPath.value, {
  immediate: false,
  method: 'GET',
  responseType: 'blob',
  onResponse(response) {
    const blob = new Blob([response.response._data], { type: 'application/pdf' })
    url.value = window.URL.createObjectURL(blob)
  }
})

onMounted(async () => {
  await execute()
})
</script>

<template>
  <div>
    <iframe
      v-if="url"
      :src="url"
      width="100%"
      height="100%"
      style="border: none; min-height: 100vh;"
      frameborder="0"
      allowfullscreen
    ></iframe>
    <p v-else-if="pending">Loading...</p>
  </div>
</template>

<style scoped lang="scss">

</style>
