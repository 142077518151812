<script setup lang="ts">
import {useUser} from "~/store/user";
import useMessage from "~/composables/useMessage";

const user = useUser();
const message = useMessage();

const account = ref<any>(null);
account.value = await $fetch('/api/v1/accounts/' + user.getAccount?.id);

const onSubmit = async (form: any) => {
  await $fetch('/api/v1/accounts/' + user.getAccount?.id, {
    method: 'PUT',
    body: JSON.stringify(form),
  }).then((response: any) => {
    message.success('Änderungen gespeichert');

    account.value = response;
  }).catch(() => {
    message.error('Fehler beim Speichern');
  });
};
</script>

<template>
  <div class="w-[400px]">
    <FormKit type="form" :actions="false" :model-value="account" @submit="onSubmit">
      <h2 class="text-xl">Persönliche Daten</h2>

      <FormKit type="primeInputText" name="firstName" label="Vorname" validation="required|length:0,255" placeholder="Vorname" />
      <FormKit type="primeInputText" name="lastName" label="Nachname" validation="required|length:0,255" placeholder="Nachname" />
      <FormKit type="primeInputText" name="email" label="E-Mail" :disabled="true" placeholder="E-Mail" :readonly="true" help="Die E-Mail-Adresse kann nicht geändert werden." />

      <h2 class="text-xl mb-1 mt-4">Unternehmens-Daten</h2>
      <p class="text-gray-500 text-sm pb-2">
        Die Unternehmens-Daten werden zur Anzeige der Kontaktperson bei Angeboten und im Kundenbereich verwendet.
      </p>

      <FormKit type="base64ImageUpload" name="avatar" label="Profilbild" help="Wird für die Kommunikation mit Kunden verwendet." :preview-rounded="true" />
      <FormKit type="primeInputText" name="position" label="Position" placeholder="CEO, Geschäftsführer, etc." help="Wird für die Kommunikation mit Kunden verwendet." />
      <FormKit type="primeInputText" name="phone" label="Telefon" placeholder="Telefon" help="Wird für die Kommunikation mit Kunden verwendet." />
      <FormKit type="primeInputText" name="linkedin" label="LinkedIn" placeholder="LinkedIn" help="Wird für die Kommunikation mit Kunden verwendet." />

      <FormKit type="primeSubmitButton" submit-label="Änderungen speichern" button-icon="pi pi-save" class="mt-3" />

    </FormKit>
  </div>
</template>

<style scoped lang="scss">

</style>
