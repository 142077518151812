<script setup lang="ts">
import {$fetch} from "ofetch";

const dialogRef = inject('dialogRef')

const subscriptionId = dialogRef.value?.data?.subscriptionId ?? null
if (!subscriptionId) {
  throw new Error('[SubscriptionEstimatedInvoicePreview] subscriptionId is required')
}

const apiEndpoint = computed(() => {
  return `/api/v1/subscriptions/${subscriptionId}/estimated-invoice`
})

const url = ref('')

await $fetch(apiEndpoint.value, {
  method: 'GET',
  responseType: 'blob',
  retry: 3,
  retryDelay: 1000,
  retryStatusCodes: [500, 502],
  onResponse(response) {
    const blob = new Blob([response.response._data], { type: 'application/pdf' })
    url.value = window.URL.createObjectURL(blob)
  }
}).catch(() => {
})
</script>

<template>
  <div>
    <iframe
      v-if="url"
      :src="url"
      width="100%"
      height="100%"
      style="border: none; min-height: 100vh;"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<style scoped lang="scss">

</style>
