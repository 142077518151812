<script setup lang="ts">
import {Ref} from "@vue/reactivity";
import {FormKitSchemaDefinition} from "@formkit/core";
import {FormatMoney} from "#components";
import Button from "primevue/button";
import {useToast} from "primevue/usetoast";

const dialogRef = inject('dialogRef')
const toast = useToast()

const props = defineProps({
  invoiceId: {
    type: String,
    required: false
  },
  currencyCode: {
    type: String,
    required: false
  },
  customerId: {
    type: String,
    required: false
  },
  unpaidAmount: {
    type: Number,
    required: false
  },
})

const invoiceId = dialogRef.value?.data?.invoiceId ?? props.invoiceId ?? null
const currencyCode = dialogRef.value?.data?.currencyCode ?? props.currencyCode ?? null
const customerId = dialogRef.value?.data?.customerId ?? props.customerId ?? null
const unpaidAmount = dialogRef.value?.data?.unpaidAmount ?? props.unpaidAmount ?? null
const invoiceNumber = dialogRef.value?.data?.invoiceNumber ?? null
const paymentMethodId = dialogRef.value?.data?.paymentMethodId ?? null
if (!invoiceId) {
  throw new Error('[TransactionForm] No invoiceId provided')
}

if (!currencyCode) {
  throw new Error('[TransactionForm] No currencyCode provided')
}

if (!customerId) {
  throw new Error('[TransactionForm] No customerId provided')
}

const data = ref({
  unpaidAmount,
  amount: unpaidAmount,
  paymentMethod: paymentMethodId,
})

const isLoading = ref(false)

const unpaidAmountHint = {
  $el: 'div',
  attrs: {
    class: 'md:flex md:items-center md:justify-between mt-2'
  },
  children: [
    {
      $el: 'label',
      children: ['Offener Betrag nach Zahlung'],
    },
    {
      $el: 'p',
      attrs: {
        class: {
          if: '($value.unpaidAmount - $value.amount) > 0',
          then: 'text-red-500',
          else: 'text-green-500'
        }
      },
      children: [
        {
          $cmp: 'FormatMoney',
          props: {
            amount: '$value.unpaidAmount - $value.amount',
            currencyCode: currencyCode,
            as: 'span',
          },
        }
      ]
    },
  ]
};

const currentInvoiceHint = {
  $el: 'div',
  attrs: {
    class: 'md:flex md:items-center md:justify-between mt-2'
  },
  children: [
    {
      $el: 'label',
      children: ['Belegnummer'],
    },
    {
      $el: 'p',
      children: [invoiceNumber]
    },
  ]
};
const formSchema: Ref<FormKitSchemaDefinition> = reactive({
  $formkit: 'form',
  actions: false,
  children: [
    currentInvoiceHint,
    unpaidAmountHint,
    {
      $formkit: 'primeInputNumber',
      name: 'amount',
      label: 'Betrag',
      mode: 'currency',
      currency: currencyCode,
      minFractionDigits: 2,
      maxFractionDigits: 10,
      validation: 'required',
    },
    {
      $formkit: 'paymentMethodDropdown',
      name: 'paymentMethod',
      label: 'Zahlungsmethode',
      validation: 'required',
      customerId: customerId,
      value: paymentMethodId,
    },
    {
      $formkit: 'hidden',
      name: 'unpaidAmount',
      value: unpaidAmount,
    },
    {
      $formkit: 'primeInputText',
      name: 'description',
      label: 'Beschreibung / Referenz',
      validation: 'required|length:3,100',
      value: 'Zahlung für Beleg ' + invoiceNumber,
    },
    {
      $formkit: 'calendar',
      name: 'paymentDate',
      label: 'Zahlungsdatum',
      validation: 'required',
      value: new Date(),
    },
    {
      $el: 'div',
      attrs: {
        class: 'md:flex md:items-center md:justify-between mt-2'
      },
      children: [
        {
          $el: 'label',
          children: ['Gebühren erfassen'],
        },
        {
          $formkit: 'primeCheckbox',
          name: 'collectFees',
          value: false,
        },
      ]
    },
    {
      if: '$value.collectFees',
      $formkit: 'primeInputNumber',
      name: 'feeAmount',
      mode: 'currency',
      currency: currencyCode,
      minFractionDigits: 2,
      maxFractionDigits: 2,
      validation: 'required',
      value: 0,
      help: 'Gebühren die durch die Zahlungsmethode anfallen. Dient ausschließlich zur Dokumentation und Auswertung.',
    },
    {
      $cmp: 'Button',
      props: {
        loading: isLoading,
        label: {
          if: '($value.unpaidAmount - $value.amount) > 0',
          then: 'Teilzahlung erfassen',
          else: 'Beleg begleichen'
        },
        type: 'submit',
        icon: {
          if: '($value.unpaidAmount - $value.amount) > 0',
          then: 'pi pi-plus',
          else: 'pi pi-check'
        },
        class: 'w-full',
      }
    }
  ]
})

const onSubmit = async (data: any) => {
  isLoading.value = true
  await $fetch(`/api/v1/invoices/${invoiceId}/payments`, {
    method: 'POST',
    body: JSON.stringify({
      ...data,
    }),
    onResponse(response) {
      if (response.response.status === 201) {
        dialogRef.value?.close(response.response._data)
      } else {
        toast.add({
          severity: 'error',
          summary: 'Fehler',
          detail: 'Zahlung konnte nicht erfasst werden',
          life: 3500
        })
      }
    }
  }).catch(() => {
    toast.add({
      severity: 'error',
      summary: 'Fehler',
      detail: 'Zahlung konnte nicht erfasst werden. Bitte überprüfe deine Eingaben.',
      life: 3500
    })
  })

  isLoading.value = false
}

const library = markRaw({
  FormatMoney: FormatMoney,
  Button: Button
})
</script>

<template>
  <div class="w-full sm:min-w-[340px] sm:max-w-[340px]">
    <FormKitSchema :schema="formSchema" @submit="onSubmit" v-model="data" :library="library" />
  </div>
</template>

<style scoped lang="scss">

</style>
