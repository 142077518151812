import CreateProductForm from "~/components/Product/CreateProductForm.vue";
import {usePermissions} from "~/composables/usePermissions";
import {AppPermissions} from "~/types/Permissions";
import useModal from "~/composables/useModal";
import {ReturnType} from "birpc";

export default class ProductService {
  private readonly dialog;
  private readonly confirm;
  private readonly message;

  constructor(dialog: ReturnType<typeof useModal>, confirm: ReturnType<typeof useConfirmation>, message: ReturnType<typeof useMessage>) {
    this.dialog = dialog;
    this.confirm = confirm;
    this.message = message;
  }

  public async archiveProduct(productId: string, name: string) {
    if (!usePermissions().can(AppPermissions.ProductWrite)) {
      return;
    }

    const result = await this.confirm.waitingConfirm({
      header: 'Produkt archivieren',
      message: `Soll das Produkt "${name}" wirklich archiviert werden? Dies kann nicht rückgängig gemacht werden. Anschließend ist das Produkt für neue Abonnements und Rechnungen nicht mehr auswählbar.`,
      acceptLabel: 'Produkt archivieren',
      acceptClass: 'p-button-danger',
      acceptIcon: 'pi pi-trash',
    });

    if (result) {
      const loadingDialog = this.dialog.loading('Archiviere Produkt...');
      try {
        await $fetch('/api/v1/catalogue/products/' + productId + '/archive', {
          method: 'PUT',
        });
        await refreshNuxtData('product-detail-' + productId);
        this.message.success('Produkt wurde archiviert.');
      } catch (e) {
        this.message.error('Produkt konnte nicht archiviert werden.');
        return;
      } finally {
        loadingDialog.close();
      }
    }
  }

  public openNewProduct() {
    if (!usePermissions().can(AppPermissions.ProductWrite)) {
      return;
    }

    this.dialog.open(CreateProductForm, {
      props: {
        header: 'Neues Produkt erstellen',
        modal: true,
      },
      onClose: (data: any) => {
        if (data.data) {
          navigateTo(`/catalogue/products/${data.data.id}`);
        }
      },
    });
  }
  getProductsSmall() {
    return fetch('/data/products-small.json')
      .then((res) => res.json())
      .then((d) => d.data);
  }

  getProducts() {
    return fetch('/data/products.json')
      .then((res) => res.json())
      .then((d) => d.data);
  }

  getProductsWithOrdersSmall() {
    return fetch('/data/products-orders-small.json')
      .then((res) => res.json())
      .then((d) => d.data);
  }
}
