<script setup lang="ts">
import {computed} from "vue";
import {PropType} from "vue/dist/vue";

const props = defineProps({
  keys: {
    type: Object as PropType<string | string[]>,
    required: true
  }
})

const allKeys = computed(() => {
  if (Array.isArray(props.keys)) {
    return props.keys;
  }

  return props.keys.split(' ');
})
</script>

<template>
  <div class="flex gap-1 app-kbd-list">
    <kbd class="bg-gray-50 border-gray-100" style="font-family: monospace;" v-for="(key, index) in allKeys" :key="key + index">{{ key }}</kbd>
  </div>
</template>

<style scoped lang="scss">
.app-kbd-list {
  kbd {
    @apply inline-flex items-center border px-2 font-semibold font-sans text-sm text-gray-500;
  }
  kbd:first-child {
    @apply rounded-l;
  }

  kbd:last-child {
    @apply rounded-r;
  }
}
</style>
