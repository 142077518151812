import { defineStore } from 'pinia';

export const useSearch = defineStore('search', {
  state: () => ({
    lastSearchResult: [],
    lastSearchMenuItem: 'all',
    lastSearchResultEstimatedTotalHits: {},
  }),
  actions: {
    async setLastSearchResult(searchResult: any) {
      this.lastSearchResult = searchResult;
    },
    async setLastSearchMenuItem(menuItem: string) {
      this.lastSearchMenuItem = menuItem;
    },
    async setLastSearchResultEstimatedTotalHits(estimatedTotalHits: any) {
      this.lastSearchResultEstimatedTotalHits = estimatedTotalHits;
    },
  },
  getters: {
    getLastSearchResult: (state) => () => {
      return state.lastSearchResult;
    },
    getLastSearchMenuItem: (state) => () => {
      return state.lastSearchMenuItem
    },
    getLastSearchResultEstimatedTotalHits: (state) => () => {
      return state.lastSearchResultEstimatedTotalHits;
    },
  },
});
