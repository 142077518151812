import { $fetch } from 'ofetch';
import AppLoadingDialogContent from '~/components/AppLoadingDialogContent.vue';
import { useFetch } from '#app';
import ViewInvoicePdfFooter from '~/components/Invoice/ViewInvoicePdfFooter.vue';
import TransactionForm from '~/components/Transaction/TransactionForm.vue';
import FinalizeInvoiceConfirmSuspense from '~/components/Invoice/FinalizeInvoiceConfirmSuspense.vue';
import ViewInvoicePdfSupsense from '~/components/Invoice/ViewInvoicePdfSupsense.vue';
import PreviewInvoiceSuspense from '~/components/Invoice/PreviewInvoiceSuspense.vue';
import CancelInvoiceModal from '~/components/Invoice/CancelInvoiceModal.vue';
import InvoiceFormModalSuspense from '~/components/Invoice/InvoiceFormModalSuspense.vue';
import { DocumentType } from '~/types/Document';
import DeleteInvoiceModal from "~/components/Invoice/DeleteInvoiceModal.vue";
import CloseInvoiceModal from "~/components/Invoice/CloseInvoiceModal.vue";

export const useInvoiceActions = () => {
  const addPayment = ({ dialog, toast, refresh, invoiceNumber, invoiceId, currencyCode, customerId, unpaidAmount, paymentMethodId }) => {
    dialog.open(TransactionForm, {
      props: {
        modal: true,
        header: 'Zahlung erfassen',
        closable: true,
      },
      data: {
        invoiceNumber,
        invoiceId,
        currencyCode,
        customerId,
        unpaidAmount,
        paymentMethodId,
      },
      onClose: async (data: any) => {
        if (data.data) {
          refresh();
          toast.add({
            severity: 'success',
            summary: 'Zahlung erfasst',
            detail: 'Die Zahlung wurde erfolgreich erfasst.',
            life: 3500,
          });
        }
      },
    });
  };

  const finalizeInvoiceDo = async ({ dialog, toast, invoiceId, item, refresh, dueDate, openPreview }) => {
    const loadingDialog = dialog.open(AppLoadingDialogContent, {
      props: {
        modal: true,
        showHeader: false,
        closable: false,
      },
      data: {
        message: 'Die Rechnung wird finalisiert...',
      },
      onClose: async () => {},
    });

    const openPreviewDialog = openPreview !== false;

    await $fetch('/api/v1/invoices/' + invoiceId + '/finalize', {
      method: 'POST',
      body: JSON.stringify({
        dueDate: !dueDate ? null : dueDate,
      }),
      onResponse({ response }) {
        if (response.status !== 201 && response.status !== 200) {
          toast.add({
            severity: 'error',
            summary: 'Rechnung konnte nicht finalisiert werden',
            detail: 'Die Rechnung konnte nicht finalisiert werden. Versuche es später erneut.',
            life: 3500,
          });

          loadingDialog.close();

          return;
        }

        let refreshCount = 0;

        const { refresh: refreshInvoiceStatus } = useFetch('/api/v1/invoices/' + invoiceId, {
          async onResponse(response) {
            if (response.response.status !== 200) {
              // wait 1 second and try again
              await new Promise((resolve) => setTimeout(resolve, 1000));
              refreshCount++;
              refreshInvoiceStatus();
            }

            if (refreshCount > 15) {
              toast.add({
                severity: 'error',
                summary: 'Rechnung konnte nicht finalisiert werden',
                detail: 'Der Prozess dauert ungewöhnlich lange. Wir informieren dich sobald die Rechnung finalisiert wurde.',
                life: 3500,
              });

              loadingDialog.close();

              return;
            }

            if (
              response.response._data.status !== 'STATUS_FINALIZING' &&
              response.response._data.status !== 'STATUS_DRAFT' &&
              response.response._data.status !== 'STATUS_NEW'
            ) {
              item.value = response.response._data;

              loadingDialog.close();

              toast.add({
                severity: 'success',
                summary: 'Rechnung finalisiert',
                detail: 'Die Rechnung wurde erfolgreich finalisiert.',
                life: 3500,
              });

              if (openPreviewDialog) {
                const pdfDialog = dialog.open(ViewInvoicePdfSupsense, {
                  props: {
                    modal: true,
                    header: 'Rechnungsvorschau',
                    closable: true,
                    showHeader: true,
                    style: {
                      width: '90%',
                      height: '90%',
                      maxWidth: '90%',
                      maxHeight: '90%',
                    },
                  },
                  templates: {
                    footer: markRaw(ViewInvoicePdfFooter),
                  },
                  data: {
                    fileId: item.value?.file?.id,
                    invoiceId: item.value?.id,
                  },
                  onClose: async () => {
                    await refresh();
                  },
                });
              } else {
                await refresh();
              }
            } else {
              // wait 1 second and try again
              await new Promise((resolve) => setTimeout(resolve, 1000));
              refreshCount++;
              await refreshInvoiceStatus();
            }
          },
        });
      },
    });
  };

  const finalizeInvoice = async ({ dialog, toast, invoiceId, item, refresh }) => {
    dialog.open(FinalizeInvoiceConfirmSuspense, {
      data: {
        item,
      },
      props: {
        header: 'Rechnung finalisieren',
        modal: true,
        draggable: false,
        closable: true,
      },
      onClose({ data }) {
        if (data?.dueDate === undefined) {
          return;
        }

        const dueDateString = !data?.dueDate ? null : new Date(data.dueDate).toISOString();
        const openPreview = data.openPreview ?? false;

        finalizeInvoiceDo({ dialog, toast, invoiceId, item, refresh, dueDate: dueDateString, openPreview });
      },
    });
  };

  const pdfDownload = (invoiceData: any) => {
    const a = document.createElement('a');
    a.href = '/api/v1/media/' + invoiceData?.file?.id + '/download?filename=' + invoiceData?.number + '.pdf';
    a.click();
    a.remove();
  };

  const previewInvoice = async ({ dialog, invoiceId }) => {
    const pdfDialog = dialog.open(PreviewInvoiceSuspense, {
      props: {
        modal: true,
        header: 'Rechnungsvorschau',
        closable: true,
        showHeader: true,
        style:  'width: 90%; height: 90%; maxWidth: 90%; maxHeight: 90%;'
      },
      data: {
        invoiceId,
      },
    });
  };

  const resendInvoice = async ({ toast, dialog, invoiceId }) => {
    const loadingDialog = dialog.open(AppLoadingDialogContent, {
      props: {
        modal: true,
        showHeader: false,
        closable: false,
      },
      data: {
        message: 'Beleg wird erneut versendet...',
      },
    });

    await $fetch('/api/v1/invoices/' + invoiceId + '/resend', {
      method: 'PUT',
      async onResponse({ response }) {
        if (response.status === 200) {
          await refreshNuxtData(['invoice-detail-' + invoiceId, 'activities']);

          toast.add({
            severity: 'success',
            summary: 'Beleg erneut versendet',
            detail: 'Der Beleg wurde erfolgreich erneut versendet.',
            life: 3500,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Beleg konnte nicht erneut versendet werden',
            detail: 'Der Beleg konnte nicht erneut versendet werden.',
            life: 3500,
          });
        }

        loadingDialog.close();
      },
    });
  };

  const duplicateInvoice = async ({ toast, dialog, invoiceId }) => {
    const loadingDialog = dialog.open(AppLoadingDialogContent, {
      props: {
        modal: true,
        showHeader: false,
        closable: false,
      },
      data: {
        message: 'Beleg wird dupliziert...',
      },
    });

    await $fetch('/api/v1/invoices/' + invoiceId + '/duplicate', {
      method: 'POST',
      async onResponse({ response }) {
        if (response.status === 201) {
          navigateTo('/invoices/' + response._data.id);

          toast.add({
            severity: 'success',
            summary: 'Beleg dupliziert',
            detail: 'Der Beleg wurde erfolgreich dupliziert.',
            life: 3500,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Beleg konnte nicht dupliziert werden',
            detail: 'Der Beleg konnte nicht dupliziert werden.',
            life: 3500,
          });
        }

        loadingDialog.close();
      },
    });
  };

  const cancelInvoiceConfirm = async ({ toast, dialog, invoice }) => {
    dialog.open(CancelInvoiceModal, {
      props: {
        modal: true,
        header: 'Rechnung stornieren',
        closable: true,
        draggable: false,
      },
      data: {
        invoice,
      },
    });
  };

  const closeInvoiceConfirm = async ({ toast, dialog, invoice }) => {
    dialog.open(CloseInvoiceModal, {
      props: {
        modal: true,
        header: 'Rechnung schließen',
        closable: true,
        draggable: false,
      },
      data: {
        invoice,
      },
    });
  };

  const deleteInvoiceConfirm = async ({ toast, dialog, invoice }) => {
    dialog.open(DeleteInvoiceModal, {
      props: {
        modal: true,
        header: 'Rechnung löschen',
        closable: true,
        draggable: false,
      },
      data: {
        invoice,
      },
    });
  };

  const cancelInvoice = async ({ toast, dialog, invoiceId }) => {
    const loadingDialog = dialog.open(AppLoadingDialogContent, {
      props: {
        modal: true,
        showHeader: false,
        closable: false,
      },
      data: {
        message: 'Beleg wird storniert...',
      },
    });

    return await $fetch('/api/v1/invoices/' + invoiceId + '/cancel', {
      method: 'POST',
      async onResponse({ response }) {
        if (response.status === 201) {
          await refreshNuxtData(['invoice-detail-' + invoiceId, 'activities']);

          toast.add({
            severity: 'success',
            summary: 'Beleg storniert',
            detail: 'Der Beleg wurde erfolgreich storniert.',
            life: 3500,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Beleg konnte nicht storniert werden',
            detail: 'Der Beleg konnte nicht storniert werden.',
            life: 3500,
          });

          throw new Error('Beleg konnte nicht storniert werden');
        }
      },
    }).finally(() => {
      loadingDialog.close();
    });
  };

  const deleteInvoice = async ({ toast, dialog, invoiceId }) => {
    const loadingDialog = dialog.open(AppLoadingDialogContent, {
      props: {
        modal: true,
        showHeader: false,
        closable: false,
      },
      data: {
        message: 'Beleg wird gelöscht...',
      },
    });

    return await $fetch('/api/v1/invoices/' + invoiceId, {
      method: 'DELETE',
      async onResponse({ response }) {
        if (response.status === 204) {
          navigateTo('/invoices');

          toast.add({
            severity: 'success',
            summary: 'Beleg gelöscht',
            detail: 'Der Beleg wurde erfolgreich gelöscht.',
            life: 3500,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Beleg konnte nicht gelöscht werden',
            detail: 'Der Beleg konnte nicht gelöscht werden.',
            life: 3500,
          });

          throw new Error('Beleg konnte nicht gelöscht werden');
        }
      },
    }).finally(() => {
      loadingDialog.close();
    });
  }

  const closeInvoice = async ({ toast, dialog, invoiceId, comment }) => {
    const loadingDialog = dialog.open(AppLoadingDialogContent, {
      props: {
        modal: true,
        showHeader: false,
        closable: false,
      },
      data: {
        message: 'Beleg wird geschlossen...',
      },
    });

    return await $fetch('/api/v1/invoices/' + invoiceId + '/close', {
      method: 'PUT',
      body: JSON.stringify({
        comment,
      }),
      async onResponse({ response }) {
        if (response.status === 201) {
          await refreshNuxtData(['invoice-detail-' + invoiceId, 'activities']);

          toast.add({
            severity: 'success',
            summary: 'Beleg geschlossen',
            detail: 'Der Beleg wurde erfolgreich geschlossen.',
            life: 3500,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: 'Beleg konnte nicht geschlossen werden',
            detail: 'Der Beleg konnte nicht geschlossen werden.',
            life: 3500,
          });

          throw new Error('Beleg konnte nicht geschlossen werden');
        }
      },
    }).finally(() => {
      loadingDialog.close();
    });
  }

  const isFinalized = (status: any) => {
    const is = status === 'STATUS_DRAFT' || status === 'STATUS_FINALIZING' || status === 'STATUS_NEW' || status === 'STATUS_CLOSED';

    return !is;
  };

  const isEditable = (status: any) => {
    return status === 'STATUS_DRAFT' || status === 'STATUS_NEW';
  };

  const openNewInvoice = (dialog: any, customerId?: string, documentType: DocumentType = DocumentType.Invoice) => {
    dialog.open(InvoiceFormModalSuspense, {
      props: {
        header: 'Rechnung erstellen',
        modal: true,
        style: {
          width: '70vw',
        },
      },
      data: {
        customerId,
        documentType,
      },
      onClose: (data: any) => {
        if (data.data && data.data.id) {
          navigateTo(`/invoices/${data.data.id}`);
        }
      },
    });
  };

  return {
    finalizeInvoice,
    finalizeInvoiceDo,
    pdfDownload,
    cancelInvoiceConfirm,
    addPayment,
    previewInvoice,
    resendInvoice,
    duplicateInvoice,
    cancelInvoice,
    isFinalized,
    isEditable,
    openNewInvoice,
    deleteInvoice,
    deleteInvoiceConfirm,
    closeInvoiceConfirm,
    closeInvoice,
  };
};
