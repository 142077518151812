import {MenuItem} from "primevue/menuitem";
import {useInvoiceActions} from "~/services/InvoiceService";
import {ReturnType} from "birpc";
import {useConfirm} from "primevue/useconfirm";
import useModal from "~/composables/useModal";
import useMessage from "~/composables/useMessage";
import {DocumentType} from "~/types/Document";
import CreateCustomerForm from "~/components/Customer/CreateCustomerForm.vue";
import CreateProductForm from "~/components/Product/CreateProductForm.vue";
import {usePermissions} from "~/composables/usePermissions";
import {AppPermissions} from "~/types/Permissions";

export class TaskCommandService {
  private confirm: ReturnType<typeof useConfirm>
  private modal: ReturnType<typeof useModal>
  private message: ReturnType<typeof useMessage>

  public constructor(
    confirm: ReturnType<typeof useConfirm>,
    modal: ReturnType<typeof useModal>,
    message: ReturnType<typeof useMessage>
  ) {
    this.confirm = confirm
    this.modal = modal
    this.message = message
  }

  private availableTaskCommands: MenuItem[] = [
    {
      label: 'Rechnung erstellen',
      command: () => {
        useInvoiceActions().openNewInvoice(this.modal, null, DocumentType.Invoice);
      },
      visible: usePermissions().can(AppPermissions.InvoiceWrite),
    },
    {
      label: 'Kunde erstellen',
      command: () => {
        this.modal.open(CreateCustomerForm, {
          props: {
            header: 'Kunde erstellen',
            style: {
              width: '70vw',
            },
          },
        });
      },
      visible: usePermissions().can(AppPermissions.CustomerWrite),
    },
    {
      label: 'Abonnement erstellen',
      command: () => {
        navigateTo('/subscriptions/new');
      },
      visible: usePermissions().can(AppPermissions.SubscriptionWrite),
    },
    {
      label: 'Produkt erstellen',
      command: () => {
        this.modal.open(CreateProductForm, {
          props: {
            header: 'Produkt erstellen',
          },
        });
      },
      visible: usePermissions().can(AppPermissions.ProductWrite),
    },
    {
      label: 'Kontobewegungen zuweisen',
      command: () => {
        navigateTo('/finance/bank-transactions');
      },
      visible: usePermissions().can(AppPermissions.BankAccountTransactionWrite),
    },
    {
      label: 'SEPA Aufträge erstellen',
      command: () => {
        navigateTo('/finance/sepa-exports');
      },
      visible: usePermissions().can(AppPermissions.SepaXmlWrite),
    }
  ];

  public async getTaskCommandList(): Promise<MenuItem[]> {
    return this.availableTaskCommands.filter((command) => command?.visible !== false);
  }
}
