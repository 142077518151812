export const navigateCtrlClick = (event: PointerEvent, url: string) => {
  if (event.ctrlKey || event.metaKey) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.click();
    a.remove();
  } else {
    navigateTo(url);
  }
};
