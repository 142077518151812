<script setup lang="ts">
import {useDialog} from "primevue/usedialog";
import {useToast} from "primevue/usetoast";
import SubscriptionService from "~/services/SubscriptionService";

const dialogRef = inject('dialogRef')
const subscriptionId = dialogRef?.value?.data?.subscriptionId
if (!subscriptionId) {
  throw new Error('subscriptionId is missing')
}

const dialog = useModal()
const toast = useToast()
const subscriptionSerivce = new SubscriptionService(dialog, toast)

const start = async (formData: any) => {
  const subscription = await subscriptionSerivce.activateSubscription(subscriptionId, formData.trialDays)
  dialogRef?.value?.close(subscription)
}

const schema = reactive({
  $formkit: 'form',
  actions: false,
  children: [
    {
      $formkit: 'primeInputNumber',
      label: 'Testzeitraum',
      name: 'trialDays',
      suffix: ' Tage',
      value: 0,
      validation: 'required',
    },
    {
      $cmp: 'Button',
      props: {
        type: 'submit',
        label: 'Testzeitraum starten',
        icon: 'pi pi-play',
        class: 'w-full mt-3 -mb-1',
        loading: '$disabled'
      },
    }
  ]
})
</script>

<template>
  <div class="w-full md:min-w-[340px]">
    <div>
      <FormKitSchema :schema="schema" @submit="start" />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
