<script setup lang="ts">
import {Ref} from "@vue/reactivity";
import {FormKitSchemaDefinition} from "@formkit/core";
import {useToast} from "primevue/usetoast";

const toast = useToast()
const dialogRef = inject('dialogRef')
const data = ref({
  customerId: dialogRef?.value?.data?.customerId,
  customer: dialogRef?.value?.data?.customerId,
})

const library = markRaw({

})

const formSchema: Ref<FormKitSchemaDefinition> = reactive({
  $formkit: 'form',
  actions: false,
  children: [
    {
      $formkit: 'primeInputText',
      name: 'name',
      label: 'Bezeichnung',
      help: 'Benutzerdefinierte Bezeichnung für das Abonnement',
      placeholder: 'bspw. Auftragsnummer, Kundenname, ...',
    },
    {
      $formkit: {
        if: '$value.customerId',
        then: 'hidden',
        else: 'customerDropdown',
      },
      name: 'customer',
      label: 'Kunde',
      validation: 'required',
      invoiceAddressField: 'customerAddressId',
      paymentMethodField: 'paymentMethodId',
      resetOnInput: ['customerAddressId', 'paymentMethodId'],
    },
    {
      if: '$value.customer',
      $formkit: 'customerAddressDropdown',
      name: 'customerAddressId',
      label: 'Rechnungsadresse',
      customerId: '$value.customer',
      showClear: true,
    },
    {
      if: '$value.customer',
      $formkit: 'paymentMethodDropdown',
      name: 'paymentMethodId',
      label: 'Zahlungsmethode',
      customerId: '$value.customer',
      showClear: true,
      defaultPlaceholder: 'Standardzahlungsmethode verwenden',
    },
    {
      $formkit: 'submitButton',
      createLabel: 'Abonnement erstellen',
      disabled: '$value.customer == undefined',
    }
  ]
})

const onSubmit = async (data: any) => {
  await $fetch('/api/v1/subscriptions', {
    method: 'POST',
    body: JSON.stringify(data),
    onResponse({ response }) {
      if (response.status === 201) {
        toast.add({
          severity: 'success',
          summary: 'Abonnement erstellt',
          detail: `Das Abonnement wurde erfolgreich erstellt.`,
        })

        dialogRef?.value?.close(response._data)
      } else {
        toast.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Das Abonnement konnte nicht erstellt werden.`,
          life: 3500,
        })
      }
    }
  })
}
</script>

<template>
  <div>
    <FormKitSchema :schema="formSchema" v-model="data" :library="library" @submit="onSubmit" />
  </div>
</template>

<style scoped lang="scss">

</style>
